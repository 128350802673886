import THEME_DICTIONARY from 'cuttlefish_theme_dir/dictionary.js'

const DICTIONARY = {
    homeViewHeader: 'Czym jest Karta Stałego Klienta?',
    homeViewDescription: 'Karta Stałego Klienta jest dla każdego pełnoletniego Klienta. Każdy posiadacz Karty uzyskuje dostęp do rabatów przez cały rok, ofert specjalnych, bezpłatnych usług, a także ekskluzywnych wydarzeń.',
    footerCopyright: 'Design & code by Expansja Advertising',
    homeAppDownloadDescription: 'Korzystaj ze zniżek, zbieraj punkty, wymieniaj na nagrody!',
    appDownloadLinkIos: 'https://www.apple.com/pl/app-store/',
    appDownloadLinkAndroid: 'https://play.google.com/',
    appInstallBtnLabel: 'Zainstaluj aplikację',
    appUpdateMessage: 'Dostępna jest aktualizacja',
    appUpdateBtnLabel: 'Odśwież aplikację',
    offerCategoryTitle: 'Kategoria:',
    issue_before_create_form_message: 'Napisz do nas jeśli chcesz złożyć reklamację / zgłosić błąd działania aplikacji lub inny problem związany z Kartą Stałego Klienta.',
    issue_create_form_title_placeholder: 'Tytuł zgłoszenia',
    issue_create_form_body_placeholder: 'Opis zgłoszenia',
    issue_create_form_submit_label: 'Wyślij zgłoszenie',
    issue_create_form_heading: 'Nowe zgłoszenie',
    issue_create_success_message: 'Zgłoszenie zostało utworzone',
    form_login_email_placeholder: 'Adres e-mail',
    form_login_phone_hint: 'Numer telefonu musi posiadać +48',
    form_login_phone_number_placeholder: 'Numer telefonu',
    form_login_password_placeholder: 'Hasło',
    form_login_submit_button_label: 'Zaloguj się',
    form_password_change_logout_info_alert: 'Zmiana hasła nie ma wpływu na wylogowanie z innych urządzeń, na których jesteś aktualnie zalogowany/a.',
    form_password_change_old_password_placeholder: 'Stare hasło *',
    form_password_change_password_placeholder: 'Nowe hasło *',
    form_password_change_password_repeat_placeholder: 'Powtórz nowe hasło *',
    form_password_change_submit_button_label: 'Zatwierdź zmianę',
    form_password_change_success_message: 'Twoje hasło zostało zmienione',
    form_password_reset_logout_info_alert: 'Zmiana hasła nie ma wpływu na wylogowanie z innych urządzeń, na których jesteś aktualnie zalogowany/a.',
    form_password_reset_authorization_code_placeholder: 'Kod autoryzacyjny *',
    form_password_reset_password_placeholder: 'Nowe hasło *',
    form_password_reset_password_repeat_placeholder: 'Powtórz nowe hasło *',
    form_password_reset_submit_button_label: 'Zmień hasło',
    form_password_reset_authorization_code_info_alert: 'Do zresetowania hasła niezbędny jest kod autoryzacyjny. Podaj adres email lub numer telefonu.',
    form_password_reset_email_placeholder: 'Email *',
    form_password_reset_phone_number_placeholder: 'Numer telefonu *',
    form_password_reset_authorization_code_submit_button_label: 'Wyślij kod',
    form_password_reset_do_not_have_code_info: 'Nie posiadam kodu autoryzacyjnego',
    form_password_reset_do_have_code_info: 'Posiadam już kod autoryzacyjny',
    form_password_reset_password_changed_success_message: 'Hasło zostało zmienione',
    form_password_reset_code_sent_success_message: 'Kod został wysłany',
    form_password_reset_return_button_label: 'Powrót',
    form_password_reset_info: 'Jeśli podczas rejestracji podałeś tylko adres e-mail lub zarówno adres e-mail, jak i numer telefonu, kod weryfikacyjny zostanie wysłany wyłącznie na Twój adres e-mail. Jeśli podałeś jedynie numer telefonu, kod weryfikacyjny zostanie przesłany na ten numer. Upewnij się, że wprowadzone dane kontaktowe są poprawne, aby otrzymać kod weryfikacyjny.',
    form_personal_data_email_placeholder: 'Adres e-mail',
    form_personal_data_email_verify_request_button_label: 'Weryfikuj e-mail',
    form_personal_data_email_verify_code_placeholder: 'Kod weryfikacyjny',
    form_personal_data_email_verify_button_label: 'Wyślij',
    form_personal_data_email_verify_verified_info: 'Zweryfikowano',
    form_personal_data_phone_number_placeholder: 'Numer telefonu',
    form_personal_data_phone_number_verify_request_button_label: 'Weryfikuj numer telefonu',
    form_personal_data_phone_number_verify_code_placeholder: 'Kod weryfikacyjny',
    form_personal_data_phone_number_verify_button_label: 'Wyślij',
    form_personal_data_phone_number_verify_verified_info: 'Zweryfikowano',
    form_personal_data_first_name_placeholder: 'Imię',
    form_personal_data_last_name_placeholder: 'Nazwisko',
    form_personal_data_username_placeholder: 'Login',
    form_personal_data_birthday_placeholder: 'Data urodzenia',
    form_personal_data_postal_code_placeholder: 'Kod pocztowy',
    form_personal_data_gender_placeholder: 'Płeć',
    form_personal_data_gender_option_male: 'Mężczyzna',
    form_personal_data_gender_option_female: 'Kobieta',
    form_personal_data_gender_option_null: 'Nie odpowiadam',
    form_personal_data_submit_button_label: 'Zapisz zmiany',
    form_personal_data_success_message: 'Twoje dane zostały zmienione',
    form_personal_data_verification_email_sent_success_message: 'Kod weryfikacyjny został wysłany na podany adres email',
    form_personal_data_verification_phone_number_sent_success_message: 'Kod weryfikacyjny został wysłany na podany nr telefonu',
    form_register_first_name_placeholder: 'Imię',
    form_register_last_name_placeholder: 'Nazwisko',
    form_register_email_placeholder: 'Adres email',
    form_register_phone_number_placeholder: 'Numer telefonu',
    form_register_username_placeholder: 'Login',
    form_register_birthday_placeholder: 'Data urodzenia',
    form_register_postal_code_placeholder: 'Kod pocztowy',
    form_register_password_placeholder: 'Hasło',
    form_register_gender_placeholder: 'Płeć',
    form_register_gender_option_male: 'Mężczyzna',
    form_register_gender_option_female: 'Kobieta',
    form_register_gender_option_null: 'Nie odpowiadam',
    form_register_submit_button_label: 'Zarejestruj się',
    form_agreements_update_success_message: 'Zmiany zostały zapisane',
    offer_listing_item_discount_label: 'Rabat',
    nav_mobile_label_start: 'Start',
    nav_mobile_label_events: 'Wydarzenia',
    nav_mobile_label_card: 'Karta',
    nav_mobile_label_scan: 'Skaner',
    nav_mobile_label_account: 'Konto',
    nav_desktop_label_start: 'Start',
    nav_desktop_label_notifications: 'Powiadomienia',
    nav_desktop_label_events: 'Akcje i eventy',
    nav_desktop_label_card: 'Karta',
    nav_desktop_label_scan: 'Skanowanie paragonu',
    nav_desktop_label_account: 'Konto',
    nav_desktop_label_offers: 'Oferty specjalne',
    nav_desktop_label_logout: 'Wyloguj',
    nav_desktop_label_my_rewards: 'Moje nagrody',
    nav_desktop_label_rewards:'Katalog nagród',
    nav_account_label_personal_data: 'Dane osobowe',
    nav_account_label_password_change: 'Zmiana hasła',
    nav_account_label_loyalty_points_history: 'Historia punktów',
    nav_account_label_receipts_history: 'Historia paragonów',
    nav_account_label_agreements: 'Aktualizacja zgód',
    nav_account_label_issues: 'Zgłoś problem',
    nav_account_label_logout: 'Wyloguj',
    agreements_accept_all_label: 'Akceptuj wszystkie',
    agreements_accept_modal_label: 'Zatwierdź zgodę',
    form_authorization_register_heading: 'Rejestracja',
    form_authorization_login_heading: 'Logowanie',
    form_authorization_password_reset_heading: 'Resetuj hasło',
    form_authorization_register_button_label: 'Zarejestruj się',
    form_authorization_login_button_label: 'Zaloguj się',
    form_authorization_password_reset_button_label: 'Resetuj hasło',
    form_authorization_remember_me_label: 'Zapamiętaj mnie',
    issues_view_open_label: 'Otwarte',
    issues_view_archive_label: 'Archiwum',
    account_view_title_account: 'Konto',
    account_view_title_account_page: 'Strona użytkownika',
    account_view_title_personal_data: 'Dane osobowe',
    account_view_title_password_change: 'Zmiana hasła',
    account_view_title_loyalty_account_changes: 'Historia punktów',
    account_view_title_receipts: 'Historia paragonów',
    account_view_title_agreements: 'Aktualizacja zgód',
    account_view_title_issues: 'Zgłoś problem',
    agreements_view_submit_button_label: 'Zapisz zmiany',
    agreements_view_revoke_info: 'W celu wycofania zgody, skontaktuj się z działem obsługi klienta poprzez wiadomość wysłaną w panelu  „zgłoś problem”',
    event_catalogue_view_title: 'Akcje i eventy',
    event_view_entity_title: 'Akcje i eventy',
    event_view_tab_label_info: 'Informacje ogólne',
    event_view_tab_label_offers: 'Oferty',
    event_view_tab_label_rewards: 'Nagrody',
    event_view_tab_label_partners: 'Partnerzy',
    notifications_view_title: 'Powiadomienia',
    notification_view_title: 'Powiadomienie',
    notifications_view_load_more_label: 'Wczytaj starsze',
    offers_view_title: 'Oferty specjalne',
    offers_view_filters_heading: 'Filtrowanie ofert',
    offers_view_filters_shops: 'Kategorie sklepów',
    offers_view_filters_reset: 'Resetuj',
    start_view_welcome_prefix: 'Witaj',
    start_view_offers_title: 'Oferty specjalne',
    start_view_load_more_offers_label: 'Pokaż wszystkie',
    register_view_submit_button_prefix: 'Masz już konto?',
    footer_copyright: 'Design & code by Expansja Advertising',
    app_download_ios_title: 'Nowy sposób instalacji!',
    app_download_ios_subtitle: 'Dodaj do ekranu początkowego',
    app_download_ios_description: 'Kliknij w ikonę <span><i class="icon-export"></i></span> w dolnym pasku nawigacji i wybierz opcję "Dodaj do ekranu początkowego"',
    app_download_ios_description_en: 'Click on the <span><i class="icon-export"></i></span> icon in the bottom navigation bar and select "Add to Home Screen"',
    scan_code_button: 'Zeskanuj kod',
    scan_receipt_button: 'Zeskanuj paragon',
    send_receipt: 'Wyslij paragon',
    send_barcode: 'Dalej',
    provide_barcode: 'Wpisz lub zeskanuj kod',
    customer_points_label_short: 'Twoje punkty w programie',
    customer_points_label: 'Twoje punkty w programie Eko Przedsionek',
    customer_points_total: 'Liczba zdobytych punktów',
    customer_points_issued: 'Liczba wydanych punktów',
    customer_points_available: 'Liczba dostępnych punktów',
    show_my_rewards_label: 'Pokaż moje nagrody',
    points: 'pkt',
    add_receipt_from_machine: 'Dodaj paragon z Butelkomatu',
    marketing_text_eko: 'Bycie eko się opłaca - zbieraj punkty i zgarniaj nagrody',
    eko_info_text_after_form: 'Nagrody możesz odebrać w Punkcie Info Posnani!',
    barcode_scanner_info: 'Przybliż kamerę telefonu do numeru pod kodem kreskowym na paragonie',
    scanner_info: 'Przybliż kamerę telefonu do paragonu żeby był dobrze widoczny',
    page_return_button_label: 'Powrót',
    receipt_accepted_long_text: 'Przyznane punkty: ',
    receipt_accepted_label: 'Paragon zweryfikowany',
    receipt_preparation_long_text: 'Dziękujemy za dodanie paragonu. Twój paragon jest właśnie sprawdzany. Organizator ma 3 dni na weryfikacje paragonu.',
    receipt_preparation_label: 'Przetwarzanie',
    receipt_canceled_long_text: 'Wystąpił problem z odczytaniem wszystkich danych, zrób zdjęcie paragonu jeszcze raz.',
    receipt_canceled_label: 'Paragon odrzucony',
    receipt_all_label: 'Wszystkie',
    receipt_prepared_label: 'Przetworzone',
    user_point_history_label: 'Dodano punkty',
    user_point_history_label_substrate: 'Odjęto punkty',
    user_point_history_description: 'Stan punktów użytkownika zmienił się o: ',
    user_point_history_description_sum: 'Stan punktów użytkownika: ',
    home_download_app: "Pobierz aplikację",
    show_rewards_catalogue: "Zobacz katalog nagród",
    show_awards: "Pokaż odebrane nagrody"
}

export default {...DICTIONARY, ...THEME_DICTIONARY}
